import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import GhostPrinter from '../assets/ghost_printer.svg'
import {
  EMPTY_FUNCTION,
  DEVICE_NOT_SUPPORTED,
  DEVICE_LEDM_ONLY,
  DHP_DISCOVERY_TREE_EMPTY_ERROR,
  DEVICE_LEDM_AND_CDM,
  DHP_GEN_TWO_MANIFEST_EMPTY_ERROR,
  DHP_EPRINT_MANIFEST_EMPTY_ERROR,
  RESOURCE_URI_FALLBACK
} from '../config/constants'
import {
  generateError,
  Logger,
  getHighestResolutionImage
} from '../utils/helpers'
import { ConfigContext } from './ConfigContext'
import { ErrorContext } from './ErrorContext'
import useCdm from 'src/hooks/useCdm'
import usePrinter from '../hooks/usePrinter'
import {
  getAvatarRegistrationHref,
  getClaimPostcardHref,
  getFingerprintHref,
  getInternetDiagnosticsHref
} from 'src/utils/cdm/helpers'
import {
  getCDMServicesDiscoveryHref,
  getCloudServiceManifestHref,
  getEPrintManifestHref,
  getIccManifestHref
} from 'src/utils/ledm/helpers'
import { DeviceImageClient } from 'src/clients/DeviceImageClient'

const DEFAULT_SUPPORT = {
  registration: null,
  registrationOverLedm: null,
  claimPostcard: null,
  claimPostcardOverLedm: null,
  fingerprint: null,
  icc: null,
  iccOverLedm: null,
  cdmServices: null // attribute reserved for hybrid LEDM
}

export const PrinterContext = React.createContext({
  discoveryTree: null,
  ePrintManifest: null,
  gen2CloudServiceManifest: null,
  iccManifest: null,
  productNumber: null,
  uuid: null,
  serialNumber: null,
  cloudId: null,
  bizModel: null,
  setBizModel: EMPTY_FUNCTION,
  setCloudId: EMPTY_FUNCTION,
  init: EMPTY_FUNCTION,
  isLedm: null,
  firmwareProtocol: null,
  supportConfig: DEFAULT_SUPPORT,
  modelName: null
})

const PrinterProvider = (props) => {
  const { sessionContext, stack } = useContext(ConfigContext)
  const { setError, error } = useContext(ErrorContext)
  const [productNumber, setProductNumber] = useState(null)
  const [printerImage, setPrinterImage] = useState(GhostPrinter)
  const [uuid, setUuid] = useState(null)
  const [serialNumber, setSerialNumber] = useState(null)
  const [discoveryTree, setDiscoveryTree] = useState(null)
  const [ePrintManifest, setEPrintManifest] = useState(null)
  const [gen2CloudServiceManifest, setGen2CloudServiceManifest] = useState(null)
  const [iccManifest, setIccManifest] = useState(null)
  const [fetchedDeviceInfo, setFetchedDeviceInfo] = useState(false)
  const [fetchedLedmManifest, setFetchedLedmManifest] = useState(false)
  const [resourceError, setResourceError] = useState(null)
  const [bizModel, setBizModel] = useState(null)
  const [cloudId, setCloudId] = useState(null)
  const [modelName, setModelName] = useState(null)
  const firmwareProtocol =
    sessionContext?.device?.fwProtocolCapability || DEVICE_NOT_SUPPORTED
  const isLedm =
    firmwareProtocol === DEVICE_LEDM_ONLY ||
    firmwareProtocol === DEVICE_LEDM_AND_CDM
  const [supportConfig, setSupportConfig] = useState(DEFAULT_SUPPORT)
  const { fetchServicesDiscovery } = useCdm()
  const {
    fetchDiscovery,
    fetchDeviceInfo,
    fetchEPrintManifest,
    fetchGen2CloudServiceManifest,
    fetchIccManifest
  } = usePrinter({
    isLedm: isLedm,
    discoveryTree: discoveryTree
  })
  /* Init method is responsible for fetching discovery tree. This will trigger
   * other dependent hooks. It should only be called if firmwareProtocol is
   * valid. */
  const init = useCallback(async () => {
    const tree = await fetchDiscovery()
    if (!tree) {
      Logger.warn('Unable to fetch discovery tree')
      setResourceError({
        errorCode: DHP_DISCOVERY_TREE_EMPTY_ERROR,
        uri: RESOURCE_URI_FALLBACK.LEDM.discoveryTree
      })
      return
    }
    setDiscoveryTree(JSON.stringify(tree))
  }, [fetchDiscovery])

  /**** BEGIN - ASYNC CALLBACKS     ****/
  /* Async hook callback for fetching LEDM ePrint manifest */
  const _fetchEPrintManifest = useCallback(async () => {
    const tree = await fetchEPrintManifest()
    if (!tree) {
      Logger.warn('Unable to fetch ePrint manifest tree')
      setResourceError({
        errorCode: DHP_EPRINT_MANIFEST_EMPTY_ERROR,
        uri: RESOURCE_URI_FALLBACK.LEDM.ePrintManifest
      })
      return
    }
    setEPrintManifest(JSON.stringify(tree))
  }, [fetchEPrintManifest])

  /* Async hook callback for fetching LEDM Gen2 Cloud Service manifest */
  const _fetchGen2CloudServiceManifest = useCallback(async () => {
    const tree = await fetchGen2CloudServiceManifest()
    if (!tree) {
      Logger.warn('Unable to fetch Gen2 Cloud Services manifest tree')
      setResourceError({
        errorCode: DHP_GEN_TWO_MANIFEST_EMPTY_ERROR,
        uri: RESOURCE_URI_FALLBACK.LEDM.gen2CloudServiceManifest
      })
      return
    }
    setGen2CloudServiceManifest(JSON.stringify(tree))
  }, [fetchGen2CloudServiceManifest])

  /* Async hook callback for fetching LEDM ICC manifest */
  const _fetchIccManifest = useCallback(async () => {
    const tree = await fetchIccManifest()
    if (!tree) {
      Logger.warn('Unable to fetch ICC manifest tree')
      return
    }
    setIccManifest(JSON.stringify(tree))
  }, [fetchIccManifest])

  /* Async hook callback for fetching product number */
  const _fetchDeviceInfo = useCallback(async () => {
    let sku = sessionContext?.device?.productNumber
    let serialNumber = sessionContext?.device?.serialNumber
    let deviceUuid = sessionContext?.device?.uuid
    let modelName = sessionContext?.device?.modelName
    if (!sku || !deviceUuid || !serialNumber || !modelName) {
      const deviceInfo = await fetchDeviceInfo()
      sku = deviceInfo.productNumber
      deviceUuid = deviceInfo.uuid
      serialNumber = deviceInfo.serialNumber
      modelName = deviceInfo.modelName
    }
    Logger.log(`product number - ${sku}`)
    Logger.log(`device uuid - ${deviceUuid}`)
    Logger.log(`serial number - ${serialNumber}`)
    Logger.log(`model name - ${modelName}`)
    setProductNumber(sku)
    setUuid(deviceUuid)
    setSerialNumber(serialNumber)
    setModelName(modelName)

    const client = new DeviceImageClient(stack)
    client.getDeviceImage(sku).then((response) => {
      const highestResolutionImage =
        getHighestResolutionImage(response?.data?.images) || GhostPrinter
      setPrinterImage(highestResolutionImage)
      Logger.log(`Successfully fetched device image  ${highestResolutionImage}`)
    })
  }, [
    stack,
    sessionContext?.device?.productNumber,
    sessionContext?.device?.uuid,
    sessionContext?.device?.serialNumber,
    sessionContext?.device?.modelName,
    fetchDeviceInfo
  ])

  /* Async hook callback for fetching CDM services discovery in order to check
   * support around cloudServices service GUN. Upon fetching and determining
   * whether CDM Avatar Registration exists, we will set the supportConfig
   * accordingly. This hook callback is reserved for hybrid LEDM devices */
  const _fetchCdmServicesDiscovery = useCallback(async () => {
    const tree = (await fetchServicesDiscovery(false))?.body?.data
    // If unable to fetch CDM services discovery, we need to treat it as LEDM
    if (!tree) {
      Logger.warn('Unable to fetch CDM services discovery tree')
      setSupportConfig({
        ...supportConfig,
        cdmServices: false
      })
      return
    }
    // Check if CDM Avatar Registration exists and set flags accordingly
    const supportRegistration = !!getAvatarRegistrationHref(tree, false)
    setSupportConfig({
      ...supportConfig,
      registration: supportRegistration,
      claimPostcard: !!getClaimPostcardHref(tree, false),
      fingerprint: !!getFingerprintHref(tree, false),
      icc: !!getInternetDiagnosticsHref(tree, false),
      cdmServices: supportRegistration
    })
  }, [fetchServicesDiscovery, supportConfig])
  /**** END  -  ASYNC USE CALLBACKS ****/

  /**** BEGIN - USE EFFECT HOOKS    ****/
  /* Hook responsible for setting generic error in case unable to pull required
   * resources */
  useEffect(() => {
    if (resourceError && !error) {
      setError(
        generateError({
          errorType: resourceError.errorCode,
          resourceUri: resourceError.uri
        })
      )
    }
  }, [resourceError, error, setError])

  /* Hook responsible for fetching device info once discovery tree is available */
  useEffect(() => {
    if (discoveryTree && !fetchedDeviceInfo) {
      setFetchedDeviceInfo(true)
      _fetchDeviceInfo().then()
    }
  }, [fetchedDeviceInfo, discoveryTree, _fetchDeviceInfo])

  /* Hook responsible for determining cdmOnly eligibility */
  useEffect(() => {
    if (!isLedm && discoveryTree && supportConfig.registration === null) {
      const tree = JSON.parse(discoveryTree)
      const supportRegistration = !!getAvatarRegistrationHref(tree, false)
      const supportClaimPostcard = !!getClaimPostcardHref(tree, false)
      setSupportConfig({
        ...supportConfig,
        registration: supportRegistration,
        claimPostcard: supportClaimPostcard,
        fingerprint: !!getFingerprintHref(tree, false),
        icc: !!getInternetDiagnosticsHref(tree, false),
        registrationOverLedm: false,
        claimPostcardOverLedm: false,
        iccOverLedm: false
      })
    }
  }, [discoveryTree, isLedm, supportConfig])

  /* Hook responsible for setting general LEDM support flags */
  useEffect(() => {
    if (
      isLedm &&
      discoveryTree &&
      supportConfig.registrationOverLedm === null
    ) {
      const tree = JSON.parse(discoveryTree)
      setSupportConfig({
        ...supportConfig,
        registrationOverLedm: !!getEPrintManifestHref(tree, false),
        claimPostcardOverLedm: !!getCloudServiceManifestHref(tree, false),
        iccOverLedm: !!getIccManifestHref(tree, false),
        cdmServices: !!getCDMServicesDiscoveryHref(tree)
      })
    }
  }, [discoveryTree, isLedm, supportConfig])

  /*
   * Hook responsible for determining non-hybrid LEDM eligibility
   * firmwareProtocol is ledmOnly; OR
   * firmwareProtocol is ledmAndCdm, and it does not support CDM Services Discovery
   */
  useEffect(() => {
    if (isLedm && supportConfig.cdmServices === false && !fetchedLedmManifest) {
      setFetchedLedmManifest(true)
      if (supportConfig.registrationOverLedm) _fetchEPrintManifest().then()
      if (supportConfig.claimPostcardOverLedm)
        _fetchGen2CloudServiceManifest().then()
      if (supportConfig.iccOverLedm) _fetchIccManifest().then()
    }
  }, [
    _fetchEPrintManifest,
    _fetchGen2CloudServiceManifest,
    _fetchIccManifest,
    fetchedLedmManifest,
    isLedm,
    supportConfig.cdmServices,
    supportConfig.claimPostcardOverLedm,
    supportConfig.iccOverLedm,
    supportConfig.registrationOverLedm
  ])

  /*
   * Hook responsible for determining hybrid LEDM eligibility
   * firmwareProtocol is ledmAndCdm, and it does support CDM Services Discovery
   */
  useEffect(() => {
    if (
      isLedm &&
      supportConfig.cdmServices === true &&
      supportConfig.registration === null
    )
      _fetchCdmServicesDiscovery().then()
  }, [
    _fetchCdmServicesDiscovery,
    isLedm,
    supportConfig.cdmServices,
    supportConfig.registration
  ])
  /**** END  -  USE EFFECT HOOKS    ****/

  const printerState = useMemo(
    () => ({
      discoveryTree,
      ePrintManifest,
      gen2CloudServiceManifest,
      iccManifest,
      productNumber,
      printerImage,
      uuid,
      serialNumber,
      cloudId,
      bizModel,
      init,
      isLedm,
      firmwareProtocol,
      supportConfig,
      setCloudId,
      setBizModel,
      modelName
    }),
    [
      discoveryTree,
      ePrintManifest,
      firmwareProtocol,
      gen2CloudServiceManifest,
      iccManifest,
      init,
      cloudId,
      bizModel,
      isLedm,
      productNumber,
      printerImage,
      supportConfig,
      uuid,
      serialNumber,
      setCloudId,
      setBizModel,
      modelName
    ]
  )

  return (
    <PrinterContext.Provider value={printerState}>
      {props.children}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider
