import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Stack } from '@jarvis/web-stratus-client'
import { DirectionContext } from '@veneer/core/dist/esm/scripts/direction'
import { EMPTY_FUNCTION, RESULT } from '../config/constants'
import { Logger } from '../utils/helpers'
import useDeviceHttpProxy from '../hooks/useDeviceHttpProxy'

export const ConfigContext = createContext({
  authProvider: null,
  sessionContext: null,
  localization: null,
  stack: Stack.pie,
  tenantHandler: null,
  shellLanguage: null,
  shellCountry: null,
  odLanguage: null,
  odCountry: null,
  getText: EMPTY_FUNCTION,
  getTextTree: EMPTY_FUNCTION,
  closeServiceInstance: EMPTY_FUNCTION,
  sessionId: null,
  sendDHPRequest: null,
  publishCdmEvent: EMPTY_FUNCTION,
  publishRumEvent: EMPTY_FUNCTION,
  isMobile: null,
  isRTL: false,
  direction: 'ltr',
  xCorrelationId: null
})

const DEFAULT_COUNTRY = 'us'
const DEFAULT_LANGUAGE = 'en'

const ConfigProvider = (props) => {
  const {
    authProvider = null,
    analytics = null,
    store = null,
    localization = {
      country: DEFAULT_COUNTRY,
      language: DEFAULT_LANGUAGE,
      enabled: false
    },
    stack = Stack.pie,
    serviceRouting = null,
    monitoring = null,
    tenantHandler = null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    t = (key, options) => key
  } = props
  const { sendDHPRequest } = useDeviceHttpProxy()
  const [sessionId, setSessionId] = useState(null)
  const country = localization.country.toUpperCase()
  const language = localization.language
  const publishCdmEvent = analytics?.publishCdmEvents || EMPTY_FUNCTION
  const sessionContext = store?.state?.onboarding?.sessionContext
  const isMobile = ['ios', 'android'].includes(sessionContext?.app?.osType)
  const { isRTL } = useContext(DirectionContext)
  const startSpan = monitoring?.startSpan || EMPTY_FUNCTION
  const isSmallville = sessionContext?.onboardingContext?.entry === 'earlyOobe'
  const xCorrelationId = sessionContext?.xCorrelationId
  const isSmbSmallville =
    isSmallville && sessionContext?.onboardingContext?.experience === 'smb'

  const closeServiceInstance = useCallback(
    async (result = RESULT.SUCCESS, output = null, errorInfo = null) => {
      const closeServiceInstanceOptions = {
        resultData: { result: result, xCorrelationId: xCorrelationId }
      }
      if (output !== null) {
        closeServiceInstanceOptions.resultData['output'] = output
      }
      if (errorInfo !== null) {
        closeServiceInstanceOptions.resultData['errorInfo'] = errorInfo
      }
      Logger.log('calling closeServiceInstance')
      Logger.log(closeServiceInstanceOptions)
      try {
        await serviceRouting.closeServiceInstance(closeServiceInstanceOptions)
      } catch (error) {
        Logger.error(`call to closeServiceInstance failed - ${error}`)
      }
    },
    [serviceRouting, xCorrelationId]
  )

  useEffect(() => {
    async function getAppSessionId() {
      let id = null
      try {
        Logger.log('calling getServiceInstanceLaunchOptions')
        const serviceLaunchOptions =
          await serviceRouting.getServiceInstanceLaunchOptions()
        Logger.log(serviceLaunchOptions)
        const { serviceOptions } = serviceLaunchOptions
        id = serviceOptions.appSessionId
      } catch (e) {
        Logger.warn(`call to getServiceInstanceLaunchOptions failed - ${e}`)
        id = `OWID-${uuidv4()}`
      }
      Logger.log(`appSessionId ${id}`)
      setSessionId(id)
    }
    getAppSessionId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configState = useMemo(
    () => ({
      isSmallville,
      isSmbSmallville,
      authProvider,
      sessionContext,
      localization,
      stack,
      tenantHandler,
      shellLanguage: language,
      shellCountry: country,
      odLanguage:
        sessionContext?.app?.locale?.split('_')?.length > 0
          ? sessionContext?.app?.locale?.split('_')[0]
          : null,
      odCountry: sessionContext?.app?.countryRegionIso || null,
      getText: (key, options) => t(key, options),
      getTextTree: (key, options = { returnObjects: true }) => t(key, options),
      closeServiceInstance,
      sessionId,
      sendDHPRequest,
      publishCdmEvent,
      publishRumEvent: async (name, attributes = {}) => {
        const span = startSpan(name, attributes)
        await span?.end?.()
      },
      isMobile,
      isRTL,
      direction: isRTL ? 'rtl' : 'ltr',
      xCorrelationId
    }),
    [
      authProvider,
      sessionContext,
      localization,
      stack,
      tenantHandler,
      language,
      country,
      closeServiceInstance,
      sessionId,
      sendDHPRequest,
      publishCdmEvent,
      isMobile,
      isRTL,
      t,
      startSpan,
      isSmallville,
      isSmbSmallville,
      xCorrelationId
    ]
  )

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}
export default ConfigProvider
